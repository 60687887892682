import { Carousel, Embla } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import { SimModel } from 'api-hooks/sim/model';
import { AssetPaths } from 'common/constants/assets';
import { SimStatusEnum } from 'common/constants/enum';
import { HighlightInstallationStamp } from 'common/repositories/highlight-installation-stamp';
import { NavigationRoutes } from 'common/routes';
import breakpoints from 'common/styles/breakpoint';
import Button from 'components/elements/button';
import { useEffectLimits } from 'hooks/use-effect-derivatives';
import useKurosimNavigation, {
  StackNavigation,
} from 'hooks/use-kurosim-navigation';
import { useKurosimNavigationEventHandler } from 'hooks/use-kurosim-navigation/popstate';
import mergeWith from 'lodash/mergeWith';
import EsimScreenBody from 'modules/esim/body';
import EsimCard from 'modules/esim/components/esim-card';
import { EsimCarouselStyles } from 'modules/esim/components/styles.css';
import { EmptyView } from 'modules/status';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';

import InstallationHighlighter, {
  InstallationHighlighterRef,
} from '../components/installation-highlighter';

interface MySimScreenProps {
  data: SimModel[];
}

const SIM_ID_PARAM_IDENTIFIER = 'sim_id';

export default function MySimScreen(props: MySimScreenProps) {
  const { data } = props;
  const [index, setIndex] = React.useState(0);
  const [embla, setEmbla] = React.useState<Embla | undefined>(undefined);
  const installationHighlighterRef =
    React.useRef<InstallationHighlighterRef>(null);

  const { t } = useTranslation();
  const { replace } = useKurosimNavigation();

  const currentSimId = React.useRef<string | undefined>(undefined);
  currentSimId.current = data[index]?.id;

  useKurosimNavigationEventHandler({
    fn(current, upcoming) {
      StackNavigation.update(
        current.pathname,
        mergeWith(current, {
          query: {
            [SIM_ID_PARAM_IDENTIFIER]: currentSimId.current,
          },
        }),
      );
    },
  });

  const router = useRouter();
  const params = useRouter().query;
  const canContinue = useEffectLimits({
    times: 1,
    enabled: !!params[SIM_ID_PARAM_IDENTIFIER] && !!embla,
  });

  React.useEffect(() => {
    if (index >= data.length) {
      setIndex(0);
    }
  }, [data.length, index]);

  React.useEffect(() => {
    if (!canContinue() || !embla) {
      return;
    }
    const simId = params[SIM_ID_PARAM_IDENTIFIER];

    const simIndex = data.findIndex((sim) => sim.id === simId);
    setIndex(simIndex);
    embla.scrollTo(simIndex);

    const newParams = { ...params };
    delete newParams[SIM_ID_PARAM_IDENTIFIER];
    router.replace(
      {
        pathname: router.pathname,
        query: newParams,
      },
      undefined,
      {
        shallow: true,
      },
    );
  }, [canContinue, data, embla, params, router]);

  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!embla) return;
    if (HighlightInstallationStamp.get()) return;
    if (data.length === 0) return;
    const simIndex = data.findIndex(
      (data) => data.status === SimStatusEnum.Assigned,
    );
    if (simIndex === -1) return;
    setTimeout(() => {
      embla.scrollTo(simIndex);
      setIndex(simIndex);
      installationHighlighterRef.current?.open();
      HighlightInstallationStamp.set();
    }, 300);
  }, [data, embla]);

  const screenMaxXxs = useMediaQuery(breakpoints.screenMaxXxs);
  if (data.length === 0) {
    return (
      <EmptyView
        imageSource={AssetPaths.NoEsim}
        title={t('error:sim_empty_title')}
        description={t('error:sim_empty_desc')}
        below={
          <Button
            fullWidth
            onClick={() => {
              replace(NavigationRoutes.Store);
            }}
          >
            {t('common:browse_plan')}
          </Button>
        }
      />
    );
  }

  return (
    <div>
      <Carousel
        slideGap={24}
        slideSize="50%"
        align="center"
        height={screenMaxXxs ? 200 + 28 : 216 + 40}
        classNames={EsimCarouselStyles}
        withIndicators
        withControls={false}
        onSlideChange={setIndex}
        getEmblaApi={setEmbla}
      >
        {data.map((esim) => (
          <Carousel.Slide key={esim.id}>
            <EsimCard
              iccid={esim.iccid}
              skin={esim.skin}
              label={esim.label}
              status={esim.status}
              Actions={<EsimCard.ActionsButton data={esim} />}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
      {data[index] && <EsimScreenBody data={data[index]} withCard={false} />}
      <InstallationHighlighter ref={installationHighlighterRef} />
    </div>
  );
}
